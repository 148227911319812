import React, {useEffect} from 'react';
import styled from "styled-components";
import { ButtonProps } from "../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import {SwiperContainer} from "../../styles/Styles";
import {Swiper, SwiperSlide} from "swiper/react";
// @ts-ignore
import {SwiperOptions} from "swiper/types";
import {Navigation, Pagination} from "swiper/modules";

interface Props {
    historyData?: any;
}

const MobileTimeLineComponent: React.FC<Props> = ({ historyData }) => {

    const isMobile = useMediaQuery({
        // 프로 맥스 기준
        query: "(max-width:430px)"
    }
    );

    const [selectedYears, setSelectedYears] = React.useState<number[]>([]);
    const [selectedData, setSelectedData] = React.useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);

    const groupYearsIntoThrees = (years: number[]) => {
        const sortedYears = years.sort((a, b) => b - a);
        const groupedYears = [];

        for (let i = 0; i < sortedYears.length; i += 3) {
            const group = sortedYears.slice(i, i + 3);
            // 그룹의 첫 번째와 마지막 연도가 같으면 단일 연도만 포함
            if (group.length === 1 || (Math.max(...group) === Math.min(...group))) {
                groupedYears.push([group[0]]);
            }
            // 그룹의 첫 번째와 두 번째 연도만 다르고 나머지가 같으면 두 개만 포함
            else if (group.length === 2 || (group[0] !== group[1] && group[1] === group[2])) {
                groupedYears.push([group[0], group[1]]);
            }
            // 모든 연도가 다르면 세 개 모두 포함
            else {
                groupedYears.push([group[0], group[1], group[2]]);
            }
        }

        return groupedYears;
    }
    const handleYearClick = (years: number[]) => {
        setSelectedYears(years);
        setSelectedData(historyData?.filter((item: any) =>  years.includes(item.year)) || []);
    }

    useEffect(() => {

        if (historyData && historyData.length > 0) {
            const firstYearGroup = groupYearsIntoThrees(historyData.map((item: any) => item.year))[0];
            handleYearClick(firstYearGroup);
        }
    }, [historyData]);

    const swiperRef = React.useRef(null);
    const swiperParams: SwiperOptions = {
        modules: [Navigation, Pagination],

        slidesPerView: 2,
        spaceBetween: 10,
        //클릭하면 슬라이드 이동
        slideToClickedSlide: true,

        breakpoints: {
            768: {
                slidesPerView: 3,
            },
            717:{
                slidesPerView:3,
            }
        }

    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', position: 'relative',width:'100%',height:'100%',overflow:'hidden'}}>
            <SwiperContainer>
                <Swiper ref={swiperRef} {...swiperParams} className={'swiper-container-years'}
                        style={{ overflow:'visible',height: '48px', position: 'relative', zIndex: 1 ,width:isMobile?'300px':'500px'}}>
                    {groupYearsIntoThrees(historyData?.map((item: any) => item.year) || []).map((yearGroup, index) => (
                        <SwiperSlide className={'swiper-slide-years'} key={index} style={{ width: '168px', height: '48px'}}>
                            <MobileYears
                                backgroundColor={selectedYears.includes(yearGroup[0]) ? '#006DFF' : '#1E222D'}
                                onClick={() => {handleYearClick(yearGroup);}}
                                key={index}>
                                <div key={index}>
                                    {yearGroup.length === 1
                                        ? `${yearGroup[0]}`
                                        : `${Math.max(...yearGroup)} ~ ${Math.min(...yearGroup)}`
                                    }
                                </div>
                            </MobileYears>
                        </SwiperSlide>
                    ))}
            </Swiper>
            </SwiperContainer>
            Modified Timeline with Year Display

            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', position: 'relative', marginTop:'60px'}}>
                {selectedData.map((item: any, index: any) =>
                    item.content.map((v: any, i: any) =>
                        <History key={`${index}-${i}`}>
                            <div className={'history_year'}>
                                {`${item.year}년 ${v.date}`}
                            </div>
                            {v.text.map((text: any, textIndex: any) =>
                                <div key={`${index}-${i}-${textIndex}`}>
                                    <div className={'history_title'}>{text}</div>
                                </div>
                            )}
                        </History>
                    )
                )}
            </div>
        </div>
    );
};

export default MobileTimeLineComponent;

const SliderContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 600px;
  height: 42px;
  overflow: auto;
  
&::-webkit-scrollbar {
    display: block; /* 스크롤바 표시 활성화 */
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc; /* 스크롤바 색상 설정 */
    border-radius: 4px; /* 스크롤바 모양 설정 */
  }

  `



const MobileYears = styled.div<ButtonProps>`
  width: 168px;
  height: 32px;
  background-color: ${props => props.backgroundColor ?? '#006DFF'};
  border-radius: 24px;
  color: #fff;
  font-size: clamp(14px, 1.5vw, 16px);
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: center;
`


const History = styled.div `
  display: flex;
  flex-direction: column;
  gap:10px;


  .history_year{
    color:#707070;
    font-size: clamp(16px, 1.5vw, 24px);
    font-weight: bold;
  }

  .history_title{
    color:#fff;
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: normal;
    white-space: pre-line;
  }

  .history_circle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #1E222D;
    top: 50%;
  }
  
`
