import React, { useEffect, useRef } from 'react';
import icon from '../../asset/images/icons/chatIcon.svg';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';

const CSchat: React.FC = () => {
    const location = window.location.pathname;
    const cs = location.includes('cs');
    const { t }: any = useTranslation();
    const iconRef = useRef<HTMLDivElement | null>(null);
    const tl = useRef<gsap.core.Timeline | null>(null);

    useEffect(() => {
        if (iconRef.current) {
            tl.current = gsap.timeline({ repeat: -1, paused: true })
                .to(iconRef.current, {
                    y: -40, // 더 높게 설정
                    duration: 0.3,
                    ease: 'bounce.out' // 바운스 이징 효과
                })
                .to(iconRef.current, {
                    y: 0,
                    duration: 0.3,
                    ease: 'bounce.out'
                });

            tl.current.play();
        }
    }, []);

    const handleMouseEnter = () => {
        if (tl.current) {
            tl.current.pause();
        }
    };

    const handleMouseLeave = () => {
        if (tl.current) {
            tl.current.play();
        }
    };

    if (cs) {
        return null;
    }

    return (
        <IconComp
            ref={iconRef}
            content={t('문의하기')}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src={icon} alt="chat icon" style={{ cursor: 'pointer' }} onClick={() => {
                    window.location.href = '/cs'
                }} />
            </div>
        </IconComp>
    );
};

export default CSchat;

const IconComp = styled.div<{ content: string }>`
    position: fixed;
    bottom: 40px;
    right: 20%;
    z-index: 1000;
    transition: all 0.2s ease-in-out;

    @media (max-width: 768px) {
        right: 20px;
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &:hover::after {
        content: '${props => props.content}';
        position: absolute;
        bottom: -10px;
        left: 15px;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        text-decoration: underline;
        text-shadow: 0 0 10px #111;
    }
`;
