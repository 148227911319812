import React from 'react';
import {createHeroMainText, createHeroSubText, MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import bg from "../../../asset/images/bg/AboutSubBG.webp";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUsSubHeroSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 1023px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    React.useEffect(() => {
            Aos.init({duration: 1000,
                easing:'ease-in-out',
                offset:0} );
        }, []
    );

    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${bg})`,
        padding: isDesktop ? '0 16%' : '0 5%',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        backgroundPosition: isDesktop ? 'center' : 'left',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
    };
    return (
        <MainContainer backgroundImage={bg}
                       justifyContent={'center'}
                       alignItems={'center'}
                       height={!isDesktop?'100vh':'1080px'}>
            <div className={'picture'} style={backgroundStyle}>
                <div style={{
                    maxWidth: '1920px', alignSelf: 'center',

                    margin: isDesktop ? '0% auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div className={'hero-main'} style={{padding: isDesktop ? '0 18%' : '0 6%'}}>
                        <div className={'hero_text'}
                             style={{
                                 display: '-webkit-box',
                                 WebkitLineClamp: 2,
                                 WebkitBoxOrient: 'horizontal',
                                 fontSize: 'clamp(24px, 4.8vw, 56px)',
                                 maxWidth: isDesktop ? '1100px' : isTablet ? '520px' : '240px',
                                 fontWeight: 'bold',
                                 overflow: 'visible',
                                 color: '#006DFF',
                                 whiteSpace: 'pre-line',
                                 letterSpacing: ' 0.0em',
                             }}>
                            {isDesktop ?
                                '다양한 분야의 기술 연구 및 개발,\n안정적인 서비스, 적극적인 고객지원을 약속합니다'
                                :
                                '다양한 분야의 기술 연구 및 개발, 안정적인 서비스,적극적인 고객지원을 약속합니다'}

                        </div>
                        <div style={{
                            marginTop: isDesktop ? '40px' : '20px',
                            color: '#fff',
                            fontSize: isDesktop ? '32px' : (isMobile ? '16px' : (isTablet ? '24px' : '32px')),
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'horizontal',
                            maxWidth: isDesktop ? '1030px' : isTablet ? '600px' : '280px',
                            maxHeight: isDesktop ? '100px' : isTablet ? '80px' : '80px',
                            overflow: 'visible',
                            whiteSpace: 'normal',
                            lineHeight: 1.3,
                        }}>
                            {'관련 분야에 알맞은 ICT 융합 서비스와 전문적인 관리를 통하여 고객이 적극적으로 기술혁신을 추구하며 큰 성과를 이루어 나갈수 있도록 지원합니다.'}
                        </div>
                    </div>
                </div>
            </div>
        </MainContainer>
);
};


export default AboutUsSubHeroSection;
