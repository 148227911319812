import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from "react-responsive";
import bg from "../../../asset/images/bg/AboutMainBG.webp";
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';
import { MainContainer } from '../../../styles/Styles';

const AboutUsMainHeroSection: React.FC = () => {
    const isDesktop = useMediaQuery({ query: "(min-width: 1025px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 430px)" });

    const mainTextRef = useRef<HTMLDivElement | null>(null);
    const subTextRef = useRef<HTMLDivElement | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    const { ref: mainContainerRef, inView: inViewMainContainer } = useInView({
        triggerOnce: false, // 여러 번 트리거되도록 설정
        threshold: 0.1 // 10%가 보이면 트리거
    });

    useEffect(() => {
        if (imageLoaded && inViewMainContainer) {
            if (mainTextRef.current) {
                gsap.fromTo(mainTextRef.current,
                    { opacity: 0, y: 50 },
                    { opacity: 1, y: 0, duration: 2, delay: 0.5, ease: 'power1.out' }
                );
            }
            if (subTextRef.current) {
                gsap.fromTo(subTextRef.current,
                    { opacity: 0, y: 50 },
                    { opacity: 1, y: 0, duration: 2, delay: 0.5, ease: 'power1.out' }
                );
            }
        }
    }, [imageLoaded, inViewMainContainer]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const backgroundStyle = {
        backgroundImage: imageLoaded ? `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${bg})` : 'none',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        backgroundPosition: isDesktop ? 'center' : 'left',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
        opacity: imageLoaded ? 1 : 0,
        transition: 'opacity 1s ease-in-out' // 이미지 로딩 완료 후 페이드 인 효과 추가
    };

    return (
        <div ref={mainContainerRef}>
            <MainContainer
                justifyContent={'center'} alignItems={'center'}
                height={!isDesktop ? '100vh' : '1080px'}>
                <div className={'picture'} style={backgroundStyle}>
                    <img src={bg} alt="background" onLoad={handleImageLoad} style={{ display: 'none' }} />
                    <div style={{
                        maxWidth: '1920px', alignSelf: 'center',
                        margin: isDesktop ? '0% auto' : '0 6%',
                        justifyContent: 'center', width: '100%'
                    }}>
                        <div className={'hero-main'} style={{ padding: isDesktop ? '0 18%' : '0 5%' }}>
                            <div ref={mainTextRef} style={{ visibility: imageLoaded ? 'visible' : 'hidden' }}>
                                <div className={'hero_text'}
                                     style={{
                                         display: '-webkit-box',
                                         WebkitLineClamp: 2,
                                         WebkitBoxOrient: 'horizontal',
                                         fontSize: 'clamp(32px, 4.8vw, 64px)',
                                         maxWidth: '100%',
                                         minWidth: '260px',
                                         fontWeight: 'bold',
                                         overflow: 'visible',
                                         whiteSpace: 'pre-line',
                                         letterSpacing: ' 0.0em',
                                     }}>
                                    {!isMobile ?
                                        '도전과 혁신으로\n4차산업 혁명의 가치를 실현합니다.'
                                        :
                                        '도전과 혁신으로 4차산업 혁명의 가치를\n실현합니다.'}
                                </div>
                            </div>
                            <div ref={subTextRef} style={{ visibility: imageLoaded ? 'visible' : 'hidden' }}>
                                <div className={'hero-sub'}>
                                    <div style={{
                                        marginTop: isDesktop ? '40px' : '20px',
                                        color: '#fff',
                                        fontSize: isDesktop ? '32px' : '16px',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'horizontal',
                                        maxWidth: isDesktop ? '820px' : '312px',
                                        maxHeight: isDesktop ? '122px' : '96px',
                                        overflow: 'visible',
                                        whiteSpace: 'pre-line',
                                        lineHeight: 1.3,
                                        position: 'relative',
                                    }}>
                                        {'국내 프레스 제조회사 70% 이상을 차지하며 직접적으로 제어 부분을 담당하고 있으며, 스마트 팩토리 구축을 위한 비즈니스 모델을 지속적으로 개발하여 시대의 흐름을 앞서가기 위해 최선을 다하고 있습니다.'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainContainer>
        </div>
    );
};

export default AboutUsMainHeroSection;
