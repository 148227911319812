import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from "react-responsive";
import { MainContainer } from "../../../styles/Styles";
import bg from "../../../asset/images/bg/solutionMain.webp";
import { gsap } from 'gsap';

const SolutionHeroSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 1023px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width: 430px)"
    });

    const mainTextRef = useRef(null);
    const subTextRef = useRef(null);

    useEffect(() => {
        const mainText = mainTextRef.current;
        const subText = subTextRef.current;

        const tl = gsap.timeline({ defaults: { ease: "power1.out" } });
        tl.fromTo(mainText, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 });
        tl.fromTo(subText, { y: -50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "-=0.5");

        return () => {
            tl.kill();
        };
    }, []);

    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundSize: 'cover',
        width: '100%',
        height: isMobile ? '100vh' : '1080px',
        backgroundPosition: isDesktop ? 'center' : '66%',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
    };

    return (
        <MainContainer
            backgroundImage={bg}
            justifyContent={'center'}
            alignItems={'flex-start'}
            height={isMobile ? '680px' : '100%'}
        >
            <div className={'picture'} style={backgroundStyle}>
                <div style={{
                    maxWidth: '1920px',
                    alignSelf: 'center',
                    margin: !isMobile ? '0 auto' : '0 6%',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <div className={'hero-main'} style={{ padding: isDesktop ? '0 18%' : '0 6%' }}>
                        <div ref={mainTextRef}>
                            <div className={'hero_text'}
                                 style={{
                                     display: '-webkit-box',
                                     WebkitLineClamp: 2,
                                     WebkitBoxOrient: 'horizontal',
                                     fontSize: 'clamp(32px, 5vw, 64px)',
                                     fontWeight: 'bold',
                                     overflow: 'visible',
                                     whiteSpace: 'pre-line',
                                     letterSpacing: ' 0.0em',
                                 }}>
                                {isMobile ?
                                    '현장 전문가의 스마트한 실시간 전사적 관리시스템'
                                    :
                                    '현장 전문가의 스마트한\n실시간 전사적 관리시스템'}
                            </div>
                        </div>
                        <div ref={subTextRef}>
                            <div className={'hero_text'}
                                 style={{
                                     marginTop: isDesktop ? '40px' : '20px',
                                     color: '#fff',
                                     fontSize: isDesktop ? '32px' : '16px',
                                 }}>
                                <div style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'horizontal',
                                    maxWidth: isDesktop ? '820px' : '312px',
                                    maxHeight: isDesktop ? '122px' : '96px',
                                    overflow: 'visible',
                                    whiteSpace: 'pre-line',
                                    lineHeight: 1.3,
                                }}>
                                    {'우리 업체에서 사용하고 있는 설비,주변장치에서부터 스마트공장 고도화를 위한 단계별 업그레이드까지 현장 전문가의 실시간 모니터링으로 관리 받아보세요!'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainContainer>
    );
};

export default React.memo(SolutionHeroSection);
