import React, { useEffect, useRef } from 'react';
import { MainContainer } from "../../../styles/Styles";
import { useMediaQuery } from "react-responsive";
import Images from "../../../styles/Images";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { gsap } from 'gsap';

const MainHeroSection: React.FC = () => {
    const { t }: any = useTranslation();
    const selectedLang = localStorage.getItem('i18nextLng') || 'en';
    const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    const heroTextRef = useRef<HTMLDivElement | null>(null);
    const subHeroRef = useRef<HTMLImageElement | null>(null);
    const serviceIconsRef = useRef<HTMLDivElement | null>(null);

    const backgroundStyle = {
        backgroundImage: `url(${Images.main.bg})`,
        backgroundSize: 'cover',
        width: '100%',
        height: isMobile ? '800px' : '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const IconArr = [
        { text: 'loT System', icon: Images.main.iot },
        { text: 'Cloud\nPlatform', icon: Images.main.cloud },
        { text: 'Industry 4.0', icon: Images.main.industry },
        { text: 'Smart\nFactory', icon: Images.main.smartFac },
    ];

    useEffect(() => {
        gsap.fromTo(heroTextRef.current, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1, ease: 'power1.out' });
        gsap.fromTo(subHeroRef.current, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1, delay: 0.5, ease: 'power1.out' });
        gsap.fromTo(serviceIconsRef.current, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1, delay: 1, ease: 'power1.out' });
    }, []);

    return (
        <React.Fragment>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <MainContainer backgroundImage={'#fff'} justifyContent={'center'} alignItems={'center'} height={isMobile ? '800px' : '100%'}>
                    <div className={'picture'} style={backgroundStyle}>
                        <div style={{
                            maxWidth: '1232px',
                            minWidth: '300px',
                            margin: !isMobile ? '0 16%' : '0 6%',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            <div style={{ width: '100%', alignSelf: 'center' }}>
                                <div className={'hero_main'} ref={heroTextRef} style={{
                                    display: 'flex', flexDirection: isMobile ? 'column' : 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '40px', }}>
                                        <div className={'hero_text'} style={{
                                            fontWeight: 'bold',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'horizontal',
                                            fontSize: selectedLang ? 'clamp(30px, 3.7vw, 48px)' : 'clamp(30px, 3.7vw, 64px)',
                                            zIndex: '2',
                                            textAlign: 'left',
                                            whiteSpace: 'nowrap',
                                            color: '#000',
                                        }}>
                                            {t('설비 장치 및 관리까지')}
                                        </div>
                                        <div className={'hero_text'} style={{
                                            fontWeight: 'bold',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'horizontal',
                                            fontSize: selectedLang ? 'clamp(30px, 3.7vw, 48px)' : 'clamp(30px, 3.7vw, 64px)',
                                            zIndex: '2',
                                            textAlign: 'left',
                                            whiteSpace: 'pre-line',
                                            color: '#006DFF'
                                        }}>
                                            {t('제스텍에서 편리하게\n원스톱으로')}
                                        </div>
                                    </div>
                                    <div className={'hero_text'} style={{
                                        fontWeight: '500',
                                        zIndex: '1',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'horizontal',
                                        width: isDesktop ? '520px' : (isMobile ? '330px' : '520px'),
                                        whiteSpace: 'pre-line',
                                        color: '#000',
                                        fontSize: 'clamp(16.5px, 2vw, 32px)',
                                        lineHeight: '1.5',
                                        margin: '38px 0 80px 0',
                                    }}>
                                        {t('모든 제품군을 ICT융합을 통해 \n지속적인 제품개발과 산업현장에 적합한\n 스마트 솔루션을 제안합니다.')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MainContainer>
                <img src={Images.main.factory} alt={'factory'} ref={subHeroRef} style={{
                    width: isDesktop ? '494px' : '266px',
                    position: 'absolute',
                    left: '70%',
                    zIndex: 3,
                    transform: isMobile ? 'translate(-50%, -60%)' : 'translate(-50%, -50%)'
                }} />
                <MainContainer justifyContent={'center'} padding={isMobile ? '150px 8%' : '0px 16%'} alignItems={'center'} backgroundColor={'#031E42'} height={isMobile ? '100%' : '900px'}>
                    <div style={{ maxWidth: '1232px', alignSelf: 'center', width: '100%' }} ref={serviceIconsRef}>
                        <div className={'hero_main'}>
                            <div className={'hero_text'} style={{ marginTop: isDesktop ? '60px' : '30px', textAlign: 'left' }}>
                                <div style={{
                                    fontWeight: '600',
                                    fontSize: 'clamp(32px, 8vw, 56px)', lineHeight: '1.2',
                                    color: '#98B9E2'
                                }}>
                                    {t('제스텍과 함께\n프레스 자동화에')}
                                </div>
                                <div style={{
                                    marginTop: '20px',
                                    fontWeight: '700',
                                    fontSize: 'clamp(42px, 8vw, 80px)', lineHeight: '1.2',
                                    color: '#fff'
                                }}>
                                    {t('한발 앞서가세요')}
                                </div>
                            </div>
                        </div>
                    </div>
                </MainContainer>
                <img src={Images.main.subHero} alt={'subHero'} style={{
                    display: 'block',
                    width: 'calc(1232/1920 * 100vw)',
                    minWidth: '312px',
                    maxWidth: '1232px',
                    position: 'relative',
                    left: '50%',
                    transform: isMobile ? 'translate(-50%, -28%)' : 'translate(-50%, -30%)',
                    zIndex: 3
                }} />
                <MainContainer justifyContent={'center'} padding={isMobile ? '0px 8%' : '0px 40%'} alignItems={'center'} backgroundColor={'#fff'} height={isMobile ? '800px' : '1200px'}>
                    <div style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }} ref={serviceIconsRef}>
                        <div style={{
                            marginTop: '20px',
                            fontWeight: '700',
                            textAlign: 'center',
                            fontSize: 'clamp(40px, 8vw, 64px)', lineHeight: '1.2',
                            color: '#484848'
                        }}>
                            {'Our Service'}
                        </div>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: isDesktop ? 'repeat(4,1fr)' : (isMobile ? 'repeat(2,1fr)' : 'repeat(2,1fr)'),
                            width: isDesktop ? '1232px' : '100%',
                            marginTop: '100px',
                            marginBottom: '100px',
                            gap: isMobile ? '20px' : '0px',
                        }}>
                            {IconArr.map((value, index) =>
                                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Icon>
                                        <img src={value.icon} alt={'icon'} style={{ width: isDesktop ? '90px' : '56px' }} />
                                    </Icon>
                                    <Text>{value.text}</Text>
                                </div>
                            )}
                        </div>
                    </div>
                </MainContainer>
            </div>
        </React.Fragment>
    );
};

export default MainHeroSection;

const Icon = styled.div`
    width: 200px;
    height: 200px;
    background-color: #CDE3FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
        width: 124px;
        height: 124px;
    }
`;

const Text = styled.div`
    color: #003A88;
    font-size: clamp(16px, 3vw, 24px);
    font-weight: 600;
    margin-top: 20px;
    align-self: center;
    white-space: pre-line;
    text-align: center;
`;
