import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from "react-responsive";
import { MainContainer } from "../../../styles/Styles";
import Images from "../../../styles/Images";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface Props {
    solutionList?: any;
}

const SolutionDetailHeroSection: React.FC<Props> = ({ solutionList }) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1441px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 1023px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width: 768px)"
    });

    const mainTextRef = useRef<HTMLDivElement | null>(null);
    const subTextRef = useRef<HTMLDivElement | null>(null);
    const contentsRef = useRef<HTMLDivElement | null>(null);
    const imgRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (mainTextRef.current) {
            gsap.fromTo(mainTextRef.current,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, ease: "power1.out" }
            );
        }
        if (subTextRef.current) {
            gsap.fromTo(subTextRef.current,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, ease: "power1.out", delay: 0.3 }
            );
        }
        if (contentsRef.current) {
            gsap.fromTo(contentsRef.current,
                { opacity: 0, y: 50 },
                { opacity: 1, y: 0, duration: 1, ease: "power1.out", delay: 0.6 }
            );
        }
        if (imgRef.current) {
            gsap.fromTo(imgRef.current,
                { opacity: 0, x: 50 },
                { opacity: 1, x: 0, duration: 1, ease: "power1.out", delay: 0.9 }
            );
        }
    }, []);

    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${solutionList.bg})`,
        backgroundSize: 'cover',
        width: '100%',
        height: isMobile ? '100vh' : '1080px',
        backgroundPosition: isDesktop ? 'center' : '66%',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <MainContainer
            backgroundImage={solutionList.bg}
            justifyContent={'center'}
            alignItems={'center'}
            height={isMobile ? '100vh' : '100%'}
        >
            <div className={'picture'} style={backgroundStyle}>
                <div style={{
                    maxWidth: '1232px',
                    minWidth: '300px',
                    margin: !isMobile ? '0 16%' : '0 6%',
                    width: '100%',
                    position: 'relative',
                }}>
                    <div className={'hero-main'}
                         style={{
                             display: 'flex',
                             width: '100%',
                             position: 'relative',
                             flexDirection: 'column',
                             zIndex: 2,
                             alignItems: 'start'
                         }}
                    >
                        <div ref={mainTextRef}>
                            {solutionList.title === '기업형 & 클라우드 솔루션' ?
                                <div className={'hero_text'}
                                     style={{
                                         display: '-webkit-box',
                                         WebkitLineClamp: 2,
                                         WebkitBoxOrient: 'horizontal',
                                         fontSize: 'clamp(32px, 5vw, 64px)',
                                         fontWeight: 'bold',
                                         overflow: 'visible',
                                         whiteSpace: 'pre-line',
                                         letterSpacing: '0.0em',
                                     }}
                                >
                                    {'기업형 &\n클라우드 솔루션'}
                                </div>
                                :
                                <div className={'hero_text'}
                                     style={{
                                         display: '-webkit-box',
                                         WebkitLineClamp: 2,
                                         WebkitBoxOrient: 'horizontal',
                                         fontSize: 'clamp(32px, 5vw, 64px)',
                                         fontWeight: 'bold',
                                         overflow: 'visible',
                                         whiteSpace: 'pre-line',
                                         letterSpacing: '0.0em',
                                     }}
                                >
                                    {solutionList.title}
                                </div>
                            }
                        </div>
                        <div ref={subTextRef}>
                            <div className={'hero_text'}
                                 style={{
                                     marginTop: isDesktop ? '40px' : '20px',
                                     color: '#fff',
                                     fontSize: isDesktop ? '32px' : '16px',
                                 }}
                            >
                                <div style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'horizontal',
                                    overflow: 'visible',
                                    whiteSpace: 'pre-line',
                                    lineHeight: 1.3,
                                }}
                                >
                                    {!isMobile && solutionList.sub}
                                </div>
                            </div>
                        </div>
                        <div ref={contentsRef}>
                            <div className={'hero_text'}
                                 style={{
                                     marginTop: isDesktop ? '40px' : '20px',
                                     color: '#fff',
                                     position: 'relative',
                                     fontSize: isDesktop ? '32px' : '18px',
                                 }}
                            >
                                <div style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'horizontal',
                                    overflow: 'visible',
                                    whiteSpace: 'pre-line',
                                    lineHeight: 1.3,
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                }}
                                >
                                    {solutionList.contents}
                                </div>
                            </div>
                        </div>
                    </div>
                    {solutionList.title === 'FAS' &&
                        <div
                            ref={imgRef}
                            style={{
                                width: '100%',
                                height: 'auto',
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                maxWidth: '820px',
                                minWidth: '380px',
                                position: 'absolute',
                                right: !isDesktop ? '-25%' : '-20%',
                                top: !isDesktop ? '8vh' : isMobile ? '15vh' : '-30%',
                            }}
                        >
                            {!isMobile &&
                                <img
                                    src={Images.fas.monitor}
                                    alt="product"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        zIndex: 1,
                                        opacity: !isMobile ? 1 : 0.8,
                                    }}
                                />}
                        </div>
                    }
                </div>
            </div>
        </MainContainer>
    );
};

export default React.memo(SolutionDetailHeroSection);
