import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import { MainContainer } from "../../../styles/Styles";
import qmark from "../../../asset/images/icons/Group 553@2x.png";
import styled from "styled-components";
import Buttons from "../../../components/buttons/Buttons";
import ConfirmPop from "../../../components/pop/ConfirmPop";
import { PatternFormat } from "react-number-format";
import { gsap } from 'gsap';
import Notiflix from "notiflix";
import emailjs from '@emailjs/browser'

const RegisterCsSection: React.FC = () => {
  const [modal, setModal] = useState<{ text: string, menu: string }>({ text: '', menu: '' });
  const [errorState, setErrorState] = useState({ name: '', message: '' });
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const emailReg = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;
  const phoneReg = /^\d{2,3}-?\d{3,4}-?\d{4}$/;

  const inputRefs = useRef<(HTMLDivElement | null)[]>([]);
  const formRef = useRef<HTMLFormElement | null>(null);
  const qmarkRef = useRef<HTMLDivElement | null>(null);

  const inquiryList = {
    companyName: '',
    name: '',
    phone: '',
    email: '',
    content: ''
  }

  const [inquiry, setInquiry] = useState(inquiryList);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInquiry({
      ...inquiry,
      [name]: value,
    });
    setErrorState({ name: '', message: '' });
  }

  const sendEmail = () => {
    Notiflix.Loading.pulse()
    emailjs.sendForm('service_zlss21s', 'template_thvnwgf', formRef.current!, 'Y5DEvwOioPjrPTpI8')
        .then((result) => {
          console.log(result.text);
          Notiflix.Loading.remove()
          setModal({ text: '문의사항이 접수되었습니다.\n감사합니다.', menu: 'complete' })
        }, (error) => {
          Notiflix.Loading.remove()
          Notiflix.Report.failure('문의사항을 전송할 수 없습니다.', '서버 에러 입니다. 관리자에게 문의해 주세요.', '확인')
          console.log(error.text);
        });
  };

  const registerInquiry = () => {
    const { companyName, name, phone, content } = inquiry;

    if (!companyName) {
      setErrorState({ name: 'company', message: '회사명을 입력하지 않았습니다.' })
      inputRefs.current[0]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else if (!name) {
      setErrorState({ name: 'name', message: '이름을 입력하지 않았습니다.' })
      inputRefs.current[1]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else if (!phone) {
      setErrorState({ name: 'phone', message: '연락처를 입력하지 않았습니다.' })
      inputRefs.current[2]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else if (!phoneReg.test(inquiry.phone)) {
      setErrorState({ name: 'phone', message: '올바른 형식이 아닙니다.' })
      inputRefs.current[2]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else if (!emailReg.test(inquiry.email) && inquiry.email) {
      setErrorState({ name: 'email', message: '올바른 이메일 형식이 아닙니다.' })
      inputRefs.current[3]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else if (!content) {
      setErrorState({ name: 'content', message: '문의사항을 입력하지 않았습니다.' })
      inputRefs.current[4]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else {
      sendEmail()
    }
  }

  const registerInput = (title: string, placeholder: string, type: string, req: boolean, value: any, name: any, error?: boolean) => {
    return (
        <InputComp error={error}>
          <div ref={(el) => (
              name === 'companyName' ? inputRefs.current[0] = el :
                  name === 'name' ? inputRefs.current[1] = el :
                      name === 'phone' ? inputRefs.current[2] = el :
                          name === 'email' ? inputRefs.current[3] = el :
                              name === 'content' ? inputRefs.current[4] = el : null
          )}
               className={'index'}>{title}
            {req ? <span className={'index-required'}>{'*'}</span> : null}
          </div>
          <div>
            <div className={'inputComp'}>
              {type === 'textarea' ?
                  <textarea placeholder={placeholder} autoComplete={'off'}
                            name={name} style={{ resize: 'none' }}
                            onChange={onChange}
                  />
                  :
                  type === 'phone' ?
                      <PatternFormat format={phoneNumberFormat(value) ?? '###-####-####'} type={'tel'} style={{ width: 'inherit' }}
                                     name={name} placeholder={placeholder ?? '내용을 입력해 주세요.'} autoComplete={'off'}
                                     value={value} onChange={onChange} className={'formatInput'}
                      />
                      :
                      <input type={type} placeholder={placeholder}
                             name={name} value={value} autoComplete={'off'}
                             onChange={onChange} />
              }
            </div>
            <div style={{ color: '#FF0000', margin: '5px 0 13px', visibility: error ? 'visible' : 'hidden', height: '19px', boxSizing: 'border-box' }}>{errorState.message}</div>
          </div>
        </InputComp>
    )
  }

  useEffect(() => {
    const qmarkAnimation = gsap.fromTo(qmarkRef.current,
        { rotationY: 0 },
        { rotationY: 360, duration: 2, repeat: -1, ease: "linear" }
    );

    // const handleMouseEnter = () => {
    //   qmarkAnimation.pause();
    // };
    //
    // const handleMouseLeave = () => {
    //   qmarkAnimation.resume();
    // };
    //
    // const qmarkElement = qmarkRef.current;
    //
    // if (qmarkElement) {
    //   qmarkElement.addEventListener("mouseenter", handleMouseEnter);
    //   qmarkElement.addEventListener("mouseleave", handleMouseLeave);
    // }
    //
    // return () => {
    //   if (qmarkElement) {
    //     qmarkElement.removeEventListener("mouseenter", handleMouseEnter);
    //     qmarkElement.removeEventListener("mouseleave", handleMouseLeave);
    //   }
    // };
  }, []);

  const phoneNumberFormat = (numberValue: string) => {
    if (numberValue?.substring(0, 2) === '01') {
      return '###-####-####'
    } else {
      if (numberValue?.substring(0, 2) === '02') {
        return '##-###-####'
      } else {
        return '###-###-####'
      }
    }
  }

  return (
      <React.Fragment>
        {modal.menu === 'complete' &&
            <ConfirmPop text={modal.text}
                        clickEvent={() => {
                          setModal({ text: '', menu: '' })
                          window.location.reload()
                        }} />}

        <MainContainer style={{
          background: '#fff',
          padding: isMobile ? '80px 8%' : '200px 16%',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
            <div ref={qmarkRef} style={{ marginBottom: '20px',cursor:'pointer' }}>
              <img src={qmark} alt={'qmark'} style={{ width: '100px', height: '100px' }} />
            </div>

            <NoticeMessage style={{
              fontWeight: 'bold',
              color: '#000', whiteSpace: 'pre-line', textAlign: 'center', lineHeight: '1.5'
            }}>
              {'궁금한 사항을 문의해주시면\n빠른 시일내에 답변드리겠습니다.'}
            </NoticeMessage>
            <form style={{ width: '100%' }} ref={formRef} onSubmit={(e) => { e.preventDefault(); registerInquiry(); }}>
              {registerInput('회사명', '회사명을 입력해주세요.', 'text', true, inquiry.companyName, 'companyName', 'company' === errorState.name)}
              {registerInput('이름', '이름을 입력해주세요.', 'text', true, inquiry.name, 'name', 'name' === errorState.name)}
              {registerInput('연락처', '예:010-0000-0000', 'phone', true, inquiry.phone, 'phone', 'phone' === errorState.name)}
              {registerInput('Email', '연락받으실 이메일을 입력해주세요.', 'text', false, inquiry.email, 'email', 'email' === errorState.name)}
              {registerInput('문의사항', '문의사항을 입력해주세요.', 'textarea', true, inquiry.content, 'content', 'content' === errorState.name)}

              <NoticeComp>
                <div className={'icon'}>!</div>
                <div style={{ fontSize: isMobile ? '12px' : '14px', color: '#000', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                  {'정보가 잘못입력된 경우 연락이 되지 않을수 있으니 정확하게 입력해주세요.'}
                </div>
              </NoticeComp>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxWidth: '608px', margin: '0 auto' }}>
                <Buttons buttonType={'blue-main'} width={isMobile ? '100%' : '608px'} height={'56px'}
                         text={'문의하기'} fontSize={isMobile ? '16px' : '20px'} fontWeight={700}
                         clickEvent={registerInquiry} />
              </div>
            </form>
          </div>
        </MainContainer>
      </React.Fragment>
  );
};

export default React.memo(RegisterCsSection);

const NoticeMessage = styled.div`
  font-size: clamp(18px, 3vw, 32px);
`

const InputComp = styled.div<{ error?: boolean }>`
  margin: 0 auto;
  input {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: Pretendard, -apple-system, sans-serif;
    &::placeholder {
      font-family: Pretendard, -apple-system, sans-serif;
    }
  }
  input[type=text] {
    width: 100%;
    height: 64px;

    @media only screen and (max-width: 768px) {
      height: 48px;
    }
  }
  textarea {
    padding: 16px 0;
    box-sizing: border-box;
    font-family: inherit;
    width: 100%;
    height: 265px;
    border: none;
    outline: none;
    white-space: pre-wrap;
  }

  width: 100%;
  max-width: 608px;
  display: flex;
  gap: 10px;
  flex-direction: column;

  .index {
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    display: flex;
  }

  .index-required {
    font-size: 16px;
    margin-left: 4px;
    font-weight: bold;
    color: #FF0C00;
  }

  .inputComp {
    display: flex;
    width: 100%;
    max-width: 608px;
    border-radius: 16px;
    padding: 0 24px;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid ${props => props.error ? '#ff0000' : '#E0E0E0'};
    min-height: 64px;
    align-items: center;

    &:focus-within {
      border: 1px solid #006DFF;
    }
  }

  //.qmark-animation {
  //  transition: all 0.2s ease-in-out;
  //  @keyframes rotate {
  //    0% {
  //      transform: rotateY(0deg);
  //    }
  //    50% {
  //      transform: rotateY(180deg);
  //    }
  //    100% {
  //      transform: rotateY(0deg);
  //    }
  //  }
  //}
`

const NoticeComp = styled.div`
  width: 100%;
  max-width: 608px;
  height: 88px;
  background-color: #EDF5FF;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  padding: 0 24px;
  margin: 0 auto 20px;

  .icon {
    width: 100%;
    height: 100%;
    max-width: 24px;
    max-height: 24px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #FFCE00;
    color: #BE1E16;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
